const scopeOptions = [
    { id: "KLP", type: "Kelompokan Rutin" },
    { id: "CR", type: "Cabe Rawit" },
    { id: "PR", type: "Pra Remaja" },
    { id: "RM", type: "Remaja" },
    { id: "PN", type: "Pra Nikah" },
    { id: "LANSIA", type: "Lansia" },
    { id: "PRIVATE", type: "Privat" },
    { id: "RAMUTAN", type: "Ramutan" },
]

export default scopeOptions